import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Hero from "@/components/hero/index.vue";
import ProfileBio from "@/components/profile-bio/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Management Team",
  components: { Hero, ProfileBio },
  setup() {
    const store = useStore();
    const heroSlide = [
      {
        title: "About Us",
        image: heroBg,
      },
    ];

    onMounted(() => {
      store.dispatch("getManagementTeam");
    });

    const managementTeam = computed(
      () => store.state.managementTeamModule.managementTeam
    );

    return {
      heroSlide,
      managementTeam,
    };
  },
});
