import { defineComponent } from "vue";

export default defineComponent({
  name: "Profile Bio",
  props: {
    name: {
      type: String,
      required: true,
    },
    bio: {
      type: Array,
      required: true
    },
    title: {
      type: String,
    },
    image: {
      type: String,
    },
  },
});
