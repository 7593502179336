<template>
  <div>
    <Hero :slideContent="heroSlide" />

    <div
      id="management-team"
      class="mt-10"
    >
      <div class="sub_headings text-2xl">Management Team</div>
      <div class="mt-6 text-xl">View the profiles of our Management Team</div>

      <div class="team_body mt-20">
        <ProfileBio
          v-for="member in managementTeam"
          :key="member.id"
          :name="member.name"
          :bio="member.profile"
          :title="member.position"
          :image="member.photo"
        />
      </div>
    </div>
  </div>
</template>

<script src="./management-team.page.js"></script>
<style src="./management-team.page.css" scoped lang="css"></style>
