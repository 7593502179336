<template>
  <div class="profile-bio mb-16">
    <div class="flex justify-between items-center h-40 md:h-52 overflow-hidden">
      <div class="flex-2 mr-4">
        <div class="flex flex-col justify-center space-y-2">
          <h1 class="font-bold text-lg md:text-2xl xl:text-4xl">{{ name }}</h1>
          <p
            class="text-sm"
            v-if="title"
          >{{ title }}</p>
        </div>
      </div>
      <div
        class="flex-1 h-full"
        :class="{ '-mb-20 md:-mb-12': image }"
      >
        <div class="h-full">
          <img
            class="object-contain"
            v-if="image"
            :src="image"
          />
          <img
            class="object-contain"
            v-else
            src="../../assets/images/nasida-logomark.svg"
          />
        </div>
      </div>
    </div>
    <hr />
    <div class="py-6">
      <p
        class="text-justify mb-4"
        v-for="paragraph in bio"
        :key="paragraph"
      >{{ paragraph }}</p>
    </div>
  </div>
</template>

<style src="./profile-bio.component.css" scoped lang="css"></style>
<script src="./profile-bio.component.js"></script>
